// Copyright 2019-2020 @paritytech/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { stringToHex } from '@polkadot/util';
import styled from '@xstyled/styled-components';
import React, { useContext, useEffect, useState } from 'react';
import { Divider, DropdownProps } from 'semantic-ui-react';

import { UserDetailsContext } from '../../context/UserDetailsContext';
import { useMetamaskLoginMutation, useMetamaskLoginStartMutation } from '../../generated/graphql';
import { useRouter } from '../../hooks';
import { handleTokenChange } from '../../services/auth.service';
import AccountSelectionForm from '../../ui-components/AccountSelectionForm';
import Button from '../../ui-components/Button';
import FilteredError from '../../ui-components/FilteredError';
import { Form } from '../../ui-components/Form';
import Loader from '../../ui-components/Loader';

interface Props {
	className?: string
	setMethod: React.Dispatch<React.SetStateAction<string>>
}

const LoginForm = ({ className, setMethod }:Props): JSX.Element => {
	const [error, setErr] = useState<Error | null>(null);
	const [address, setAddress] = useState<string>('');
	const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);
	const [isAccountLoading, setIsAccountLoading] = useState(true);
	const [accountsNotFound, setAccountsNotFound] = useState(false);
	const { history } = useRouter();
	const [metamaskLoginStartMutation] = useMetamaskLoginStartMutation();
	const [metamaskLoginMutation, { loading }] = useMetamaskLoginMutation();
	const currentUser = useContext(UserDetailsContext);

	useEffect(() => {
		if (!accounts.length) {
			getAccounts();
		}
	}, [accounts.length]);

	const getAccounts = async () => {
		const ethereum = (window as any).ethereum;

		if (!ethereum) {
			return;
		}

		const addresses = await ethereum.request({ method: 'eth_requestAccounts' });

		if (addresses.length === 0) {
			setAccountsNotFound(true);
			setIsAccountLoading(false);
			return;
		}

		setAccounts(addresses.map((address: string): InjectedAccountWithMeta => {
			const account = {
				address,
				meta: {
					genesisHash: null,
					name: 'metamask',
					source: 'metamask'
				}
			};

			return account;
		}));

		if (addresses.length > 0) {
			setAddress(addresses[0]);
		}

		setIsAccountLoading(false);
	};

	const onAccountChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		const addressValue = data.value as string;
		setAddress(addressValue);
	};

	const handleLogin = async () => {
		if (!accounts.length) {
			return getAccounts();
		}

		try {
			const { data: startResult } = await metamaskLoginStartMutation({
				variables: {
					address
				}
			});

			const signMessage = startResult?.metamaskLoginStart?.signMessage;

			if (!signMessage) {
				throw new Error('Challenge message not found');
			}

			const msg = stringToHex(signMessage);
			const from = address;

			const params = [msg, from];
			const method = 'personal_sign';

			(window as any).web3.currentProvider.sendAsync({
				from,
				method,
				params
			}, async (err: any, result: any) => {
				if (err) {
					setErr(err);
					return;
				}

				try {

					const { data: loginResult } = await metamaskLoginMutation({
						variables: {
							address,
							signature: result.result
						}
					});

					if (loginResult?.metamaskLogin?.token) {
						handleTokenChange(loginResult.metamaskLogin.token, currentUser);
						history.goBack();
					} else {
						throw new Error('Web3 Login failed');
					}
				} catch (error) {
					setErr(error);
				}
			});

		} catch (error) {
			setErr(error);
		}
	};

	return (
		<Form className={className} onSubmit={handleLogin}>
			<h3>Metamask Login</h3>
			{accountsNotFound?
				<div className='card'>
					<div className='text-muted'>You need at least one account in Metamask extenstion to login.</div>
					<div className='text-muted'>Please reload this page after adding accounts.</div>
				</div>
				: null
			}
			{isAccountLoading
				?
				<Loader text={'Requesting Metamask accounts'}/>
				:
				accounts.length > 0 &&
				<>
					<Form.Group>
						<AccountSelectionForm
							title='Choose linked account'
							accounts={accounts}
							address={address}
							onAccountChange={onAccountChange}
						/>
					</Form.Group>
					<div className={'mainButtonContainer'}>
						<Button
							primary
							disabled={loading}
							type='submit'
						>
							Login
						</Button>
					</div>
				</>
			}
			<div>
				{error?.message && <FilteredError className='info' text={error.message}/>}
			</div>
			<Divider horizontal>Or</Divider>
			<div className={'mainButtonContainer'}>
				<Button
					secondary
					disabled={loading}
					onClick={() => setMethod('web2')}
				>
					Login with username
				</Button>
			</div>
			<Divider horizontal>Or</Divider>
			<div className={'mainButtonContainer'}>
				<Button
					secondary
					disabled={loading}
					onClick={() => setMethod('polkadotjs')}
				>
					Login with polkadot.js
				</Button>
			</div>
		</Form>
	);
};

export default styled(LoginForm)`
	.mainButtonContainer {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	input.error {
		border-style: solid;
		border-width: 1px;
		border-color: red_secondary;
	}

	.info {
		margin: 10px 0;
	}

	.errorText {
		color: red_secondary;
	}

	.ui.dimmer {
		height: calc(100% - 6.5rem);
	}
`;
