// Copyright 2019-2020 @paritytech/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { ChainPropType } from '../types';

export const network = {
	KARURA: 'karura',
	KHALA: 'khala',
	KUSAMA: 'kusama',
	MOONBASE: 'moonbase',
	MOONBEAM: 'moonbeam',
	MOONRIVER: 'moonriver',
	POLKADOT: 'polkadot',
	WESTEND: 'westend'
};

export const tokenSymbol = {
	DEV: 'DEV',
	DOT: 'DOT',
	GLMR: 'GLMR',
	KAR: 'KAR',
	KHA: 'KHA',
	KSM: 'KSM',
	RIVER: 'RIVER',
	UNIT: 'UNIT',
	WND: 'WND'
};

export const chainProperties: ChainPropType = {
	[network.KHALA]: {
		blockTime: 12000,
		ss58Format: 8,
		tokenDecimals: 18,
		tokenSymbol: tokenSymbol.KHA
	},
	[network.KARURA]: {
		blockTime: 12000,
		ss58Format: 8,
		tokenDecimals: 18,
		tokenSymbol: tokenSymbol.KAR
	},
	[network.KUSAMA]: {
		blockTime: 6000,
		ss58Format: 2,
		tokenDecimals: 12,
		tokenSymbol: tokenSymbol.KSM
	},
	[network.POLKADOT]: {
		blockTime: 6000,
		ss58Format: 0,
		tokenDecimals: 10,
		tokenSymbol: tokenSymbol.DOT
	},
	[network.MOONBASE]: {
		blockTime: 6000,
		ss58Format: 0,
		tokenDecimals: 18,
		tokenSymbol: tokenSymbol.UNIT
	},
	[network.MOONRIVER]: {
		blockTime: 6000,
		ss58Format: 0,
		tokenDecimals: 18,
		tokenSymbol: tokenSymbol.RIVER
	},
	[network.MOONBEAM]: {
		blockTime: 6000,
		ss58Format: 0,
		tokenDecimals: 18,
		tokenSymbol: tokenSymbol.GLMR
	},
	[network.WESTEND]: {
		blockTime: 6000,
		ss58Format: 0,
		tokenDecimals: 10,
		tokenSymbol: tokenSymbol.WND
	}
};
