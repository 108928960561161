// Copyright 2019-2020 @paritytech/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { stringToHex } from '@polkadot/util';
import styled from '@xstyled/styled-components';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Divider, DropdownProps } from 'semantic-ui-react';

import { ModalContext } from '../../context/ModalContext';
import { UserDetailsContext } from '../../context/UserDetailsContext';
import { useMetamaskSignupConfirmMutation, useMetamaskSignupStartMutation } from '../../generated/graphql';
import { useRouter } from '../../hooks';
import { handleTokenChange } from '../../services/auth.service';
import AccountSelectionForm from '../../ui-components/AccountSelectionForm';
import Button from '../../ui-components/Button';
import FilteredError from '../../ui-components/FilteredError';
import { Form } from '../../ui-components/Form';
import getNetwork from '../../util/getNetwork';

const NETWORK = getNetwork();

interface Props {
	className?: string
	setMethod: React.Dispatch<React.SetStateAction<string>>
}

const MetamaskSignup = ({ className, setMethod }: Props): JSX.Element => {
	const [error, setErr] = useState<Error | null>(null);
	const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);
	const [address, setAddress] = useState<string>('');
	const { errors, handleSubmit, register } = useForm();
	const [metamaskSignupStartMutation] = useMetamaskSignupStartMutation();
	const [metamaskSignupConfirmMutation, { loading }] = useMetamaskSignupConfirmMutation();
	const { history } = useRouter();
	const currentUser = useContext(UserDetailsContext);
	const { setModal } = useContext(ModalContext);

	useEffect(() => {
		if (!accounts.length) {
			getAccounts();
		}
	}, [accounts.length]);

	const getAccounts = async () => {
		const ethereum = (window as any).ethereum;

		if (!ethereum) {
			return;
		}

		const addresses = await ethereum.request({ method: 'eth_requestAccounts' });

		setAccounts(addresses.map((address: string): InjectedAccountWithMeta => {
			const account = {
				address,
				meta: {
					genesisHash: null,
					name: 'metamask',
					source: 'metamask'
				}
			};

			return account;
		}));

		if (addresses.length > 0) {
			setAddress(addresses[0]);
		}
	};

	const handleSignup = async () => {
		if (!accounts.length) {
			return getAccounts();
		}

		setErr(null);

		let signMessage: any = '';

		try {
			const { data: startResult } = await metamaskSignupStartMutation({
				variables: {
					address
				}
			});

			signMessage = startResult?.metamaskSignupStart?.signMessage;
		} catch (error) {
			setErr(error);
			return;
		}

		if (!signMessage) {
			setErr(new Error('Challenge message not found'));
		}

		const msg = stringToHex(signMessage);
		const from = address;

		const params = [msg, from];
		const method = 'personal_sign';

		(window as any).web3.currentProvider.sendAsync({
			from,
			method,
			params
		}, async (err: any, result: any) => {
			if (err) {
				setErr(err);
				return;
			}

			try {
				const { data: signupResult } = await metamaskSignupConfirmMutation({
					variables: {
						address,
						network: NETWORK,
						signature: result.result
					}
				});

				if (signupResult?.metamaskSignupConfirm?.token) {
					handleTokenChange(signupResult.metamaskSignupConfirm.token, currentUser);
					setModal({
						content: 'Add an email in settings if you want to be able to recover your account!',
						title: 'Add optional email'
					});
					history.goBack();
				} else {
					setErr(new Error('Metamask signup failed'));
				}
			} catch (error) {
				setErr(error);
			}
		});

	};

	const onAccountChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		const addressValue = data.value as string;
		setAddress(addressValue);
	};

	return (
		<Form className={className} onSubmit={handleSubmit(handleSignup)}>
			<h3>Sign-up with metamask</h3>
			<Form.Group>
				<AccountSelectionForm
					title='Choose linked account'
					accounts={accounts}
					address={address}
					onAccountChange={onAccountChange}
				/>
			</Form.Group>
			<Form.Field>
				<label className='checkbox-label'>
					<input
						className={errors.termsandconditions ? 'error' : ''}
						name='termsandconditions'
						value='yes'
						ref={register({ required: true })}
						type='checkbox'
					/>
					I have read and agree to the terms of the <Link to='/terms-and-conditions'>Polkassembly end user agreement</Link>.
				</label>
				{errors.termsandconditions && <div className={'errorText'}>Please agree to the terms of the Polkassembly end user agreement.</div>}
			</Form.Field>
			<div className='text-muted'>To see how we use your personal data please see our <Link to='/privacy'>privacy notice</Link>.</div>
			<div className={'mainButtonContainer'}>
				<Button
					primary
					disabled={loading}
					type='submit'
				>
					Sign-up
				</Button>
			</div>
			<div>
				{error?.message && <FilteredError className='info' text={error.message}/>}
			</div>
			<Divider horizontal>Or</Divider>
			<div className={'mainButtonContainer'}>
				<Button
					secondary
					disabled={loading}
					onClick={() => setMethod('web2')}
				>
					Sign-up with username
				</Button>
			</div>
			<Divider horizontal>Or</Divider>
			<div className={'mainButtonContainer'}>
				<Button
					secondary
					disabled={loading}
					onClick={() => setMethod('polkadotjs')}
				>
					Sign-up with polkadot.js
				</Button>
			</div>
		</Form>
	);
};

export default styled(MetamaskSignup)`
	.mainButtonContainer {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 3rem;
	}

	input.error {
		border-style: solid;
		border-width: 1px;
		border-color: red_secondary;
	}

	.info {
		margin: 10px 0;
	}

	.errorText {
		color: red_secondary;
	}

	.checkbox-label {
		position: relative;
		bottom: 0.1rem;
		display: inline-block !important;
		font-size: sm !important;
		font-weight: 400 !important;
		color: grey_primary !important;
		a {
			color: grey_primary;
			border-bottom-style: solid;
			border-bottom-width: 1px;
			border-bottom-color: grey_primary;
		}
	}

	.ui.dimmer {
		height: calc(100% - 6.5rem);
	}
`;
