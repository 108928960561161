// Copyright 2019-2020 @paritytech/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import getNetwork from './getNetwork';

export default function(): 'khala_default_address' | 'karura_default_address' | 'kusama_default_address' | 'polkadot_default_address' | 'moonbase_default_address' | 'moonbeam_default_address' | 'moonriver_default_address' {
	const network = getNetwork();

	switch (network) {
	case 'khala':
		return 'khala_default_address';
	case 'karura':
		return 'karura_default_address';
	case 'kusama':
		return 'kusama_default_address';
	case 'polkadot':
		return 'polkadot_default_address';
	case 'moonbase':
		return 'moonbase_default_address';
	case 'moonbeam':
		return 'moonbeam_default_address';
	case 'moonriver':
		return 'moonriver_default_address';
	default:
		return 'polkadot_default_address';
	}
}
