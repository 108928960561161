// Copyright 2019-2020 @paritytech/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { stringToHex } from '@polkadot/util';
import styled from '@xstyled/styled-components';
import React, { useContext, useState } from 'react';
import { Grid, Icon, Popup } from 'semantic-ui-react';

import { NotificationContext } from '../../context/NotificationContext';
import { UserDetailsContext } from '../../context/UserDetailsContext';
import { useAddressUnlinkMutation, useMetamaskLinkConfirmMutation, useMetamaskLinkStartMutation, useSetDefaultAddressMutation } from '../../generated/graphql';
import { handleTokenChange } from '../../services/auth.service';
import { NotificationStatus } from '../../types';
import AddressComponent from '../../ui-components/Address';
import Button from '../../ui-components/Button';
import { Form } from '../../ui-components/Form';
import cleanError from '../../util/cleanError';
import getMetamaskUrl from '../../util/getMetamaskUrl';
import getNetwork from '../../util/getNetwork';

interface Props{
	className?: string
}

const NETWORK = getNetwork();

const Metamask = ({ className }: Props): JSX.Element => {
	const currentUser = useContext(UserDetailsContext);
	const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);
	const [metamaskLinkStartMutation] = useMetamaskLinkStartMutation();
	const [metamaskLinkConfirmMutation] = useMetamaskLinkConfirmMutation();
	const [addressUnlinkMutation] = useAddressUnlinkMutation();
	const [setDefaultAddressMutation] = useSetDefaultAddressMutation();
	const { queueNotification } = useContext(NotificationContext);

	const handleDetect = async () => {
		const ethereum = (window as any).ethereum;

		if (!ethereum) {
			return;
		}

		const addresses = await ethereum.request({ method: 'eth_requestAccounts' });

		setAccounts(addresses.map((address: string): InjectedAccountWithMeta => {
			const account = {
				address,
				meta: {
					genesisHash: null,
					name: 'metamask',
					source: 'metamask'
				}
			};

			return account;
		}));
	};

	const handleDefault = async (address: InjectedAccountWithMeta['address']) => {
		try {
			const addressDefaultResult = await setDefaultAddressMutation({
				variables: {
					address
				}
			});

			if (addressDefaultResult.data?.setDefaultAddress?.token) {
				handleTokenChange(addressDefaultResult.data?.setDefaultAddress?.token, currentUser);
			}

			queueNotification({
				header: 'Success!',
				message: addressDefaultResult.data?.setDefaultAddress?.message || '',
				status: NotificationStatus.SUCCESS
			});
		} catch (error) {
			console.error(error);
			queueNotification({
				header: 'Failed!',
				message: cleanError(error.message),
				status: NotificationStatus.ERROR
			});
		}
	};

	const handleLink = async (address: string) => {
		if (!address) {
			return;
		}

		try {
			const { data: startResult } = await metamaskLinkStartMutation({
				variables: {
					address
				}
			});

			const signMessage = startResult?.metamaskLinkStart?.signMessage;

			if (!signMessage) {
				throw new Error('Challenge message not found');
			}

			const msg = stringToHex(signMessage);
			const from = address;

			const params = [msg, from];
			const method = 'personal_sign';

			(window as any).web3.currentProvider.sendAsync({
				from,
				method,
				params
			}, async (err: any, result: any) => {
				if (err) {
					queueNotification({
						header: 'Failed!',
						message: cleanError(err.message),
						status: NotificationStatus.ERROR
					});
					return;
				}

				try {

					const { data: addressLinkConfirmResult } = await metamaskLinkConfirmMutation({
						variables: {
							address,
							network: NETWORK,
							signature: result.result
						}
					});

					if (addressLinkConfirmResult?.metamaskLinkConfirm?.token) {
						handleTokenChange(addressLinkConfirmResult.metamaskLinkConfirm.token, currentUser);
						queueNotification({
							header: 'Success!',
							message: 'Address Linked with account',
							status: NotificationStatus.SUCCESS
						});
					} else {
						throw new Error('Web3 Login failed');
					}
				} catch (error) {
					queueNotification({
						header: 'Failed!',
						message: cleanError(error.message),
						status: NotificationStatus.ERROR
					});
				}
			});

		} catch (error) {
			queueNotification({
				header: 'Failed!',
				message: cleanError(error.message),
				status: NotificationStatus.ERROR
			});
		}
	};

	const handleUnlink = async (address: InjectedAccountWithMeta['address']) => {
		try {
			const addressUnlinkConfirmResult = await addressUnlinkMutation({
				variables: {
					address
				}
			});

			if (addressUnlinkConfirmResult.data?.addressUnlink?.token) {
				handleTokenChange(addressUnlinkConfirmResult.data?.addressUnlink?.token, currentUser);
			}

			queueNotification({
				header: 'Success!',
				message: addressUnlinkConfirmResult?.data?.addressUnlink?.message || '',
				status: NotificationStatus.SUCCESS
			});
		} catch (error) {
			console.error(error);
			queueNotification({
				header: 'Failed!',
				message: cleanError(error.message),
				status: NotificationStatus.ERROR
			});
		}
	};

	const linkIcon = <><Icon name='chain'/>Link</>;
	const unlinkIcon = <><Icon name='broken chain'/>Unlink</>;

	const UnlinkButton = ({ address } : {address: string}) => {

		const StyledUnlinkButton = ({ withClickHandler = false }: {withClickHandler?: boolean}) =>
			<Button
				className={'social'}
				disabled={withClickHandler ? false : true}
				negative
				onClick={() => withClickHandler ? handleUnlink(address) : null }
			>
				{unlinkIcon}
			</Button>;

		return currentUser.defaultAddress === address
			? <Popup
				trigger={<span><StyledUnlinkButton/></span>}
				content={"You can't unlink your default address"}
				hoverable={true}
				position="top center"
			/>
			: <StyledUnlinkButton withClickHandler/>;
	};

	const SetDefaultAddress = ({ address }: {address : string}) => {
		return currentUser.defaultAddress !== address
			? <div className='button-container default-button'>
				<Button
					className={'social'}
					onClick={() => handleDefault(address)}
				>
					Set default
				</Button>
			</div>
			: <div className='default-label'>
				<Icon name='check'/> Default address
			</div>;
	};

	interface AccountsDetails {
		accounts: InjectedAccountWithMeta[];
		showAccounts: boolean;
		title: string;
	}

	const addressList = ({ accounts, showAccounts, title }: AccountsDetails) => {

		return (
			<>
				<Form.Group>
					<Form.Field width={16}>
						<label className='header'>{title}</label>
						<div className='ui list'>
							{accounts.map(account => {
								const address = account.address;
								const isLinked = address && currentUser.addresses?.includes(address);

								return address &&
									<Grid key={address}>
										<Grid.Column width={7}>
											<div className='item'>
												<AddressComponent className='item' address={address} extensionName={account.meta.name} />
											</div>
										</Grid.Column>
										<Grid.Column width={3}>
											<div className='button-container'>
												{ isLinked
													? <UnlinkButton address={address}/>
													: <Button
														className={'social'}
														onClick={() => handleLink(address) }
													>
														{linkIcon}
													</Button>

												}
											</div>
										</Grid.Column>
										<Grid.Column width={6} >
											{isLinked && <SetDefaultAddress address={address}/>}
										</Grid.Column>
									</Grid>;
							})}
						</div>
					</Form.Field>
				</Form.Group>
				{showAccounts && <Form.Group>
					<Form.Field width={16}>
						<div className='text-muted'>Associate your account with an on-chain address using the <a href={getMetamaskUrl()}>metamask</a>.</div>
						<div className='link-button-container'>
							<Button primary onClick={handleDetect}>
								Show available accounts
							</Button>
						</div>
					</Form.Field>
				</Form.Group>}
			</>
		);
	};

	return (
		<Form className={className} standalone={false}>
			{accounts.length
				? addressList({
					accounts,
					showAccounts: false,
					title: 'Available addresses'
				})
				: addressList({
					accounts: currentUser?.addresses?.sort().map((address): InjectedAccountWithMeta => ({
						address: address,
						meta: { source: '' }
					})) || [],
					showAccounts: true,
					title: 'Metamask addresses'
				})
			}
		</Form>
	);
};

export default styled(Metamask)`
	.button-container {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.default-label {
		display: flex;
		align-items: center;
		height: 100%;
		font-weight: 500;
		color: green_primary;
		padding: 0.7rem;
		line-height: 1.6rem;
	}
`;
